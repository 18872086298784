import { useState } from "react";
import { redirect } from "react-router-dom";
import Swal from "sweetalert2";
import { useAxiosPrivate } from "./useAxiosPrivate";

const Toast = Swal.mixin({
  toast: true,
  position: 'bottom-end',
  showConfirmButton: false,
  timer: 4000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
});

export const useApi = () => {
  const [loading, setLoading] = useState({});
  const api = useAxiosPrivate();

  function updateLoading(key, value) {
    setLoading((prev) => ({ ...prev, [key]: value }));
  }

  async function getHomeData() {
    try {
      updateLoading("home-data", true);
      const response = await api.get("/home");
      return response.data;
    } catch (error) {
      console.log(error.response);
      if (error.response.status === 401) return redirect("/auth/sign-in");
      throw error;
    } finally {
      updateLoading("home-data", false);
    }
  }

  async function userData() {
    try {
      updateLoading("user-data", true);
      const response = await api.get("/me");
      return response.data;
    } catch (error) {
      console.log(error.response);
      redirect("/auth/sign-in");
      throw error;
    } finally {
      updateLoading("user-data", false);
    }
  }

  async function login({ email, password }) {
    try {
      updateLoading("login", true);
      const response = await api.post("/login", {
        email,
        password,
      });
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      updateLoading("login", false);
    }
  }

  async function register({ name, email, password, passwordConfirm }) {
    try {
      updateLoading("register", true);
      const response = await api.post("/register", {
        name,
        email,
        password,
        password_confirmation: passwordConfirm,
      });
      return response.data;
    } catch (error) {
      Toast.fire("Tivemos um problema!", "Ocorreu um problema ao se registrar!", "error");
      throw error;
    } finally {
      updateLoading("register", false);
    }
  }

  async function sendRecoveryPassword({ email }) {
    try {
      updateLoading("forgot-password", true);
      const response = await api.post("/forgot-password", {
        email,
      });
      return response.data;
    } catch (error) {
      Toast.fire("Tivemos um problema!", "Ocorreu um problema por favor contate o suporte!", "error");
      throw error;
    } finally {
      updateLoading("forgot-password", false);
    }
  }

  async function updatePassword({ email, password, passwordConfirm, token }) {
    try {
      updateLoading("reset-password", true);
      const response = await api.post("/reset-password", {
        email,
        password,
        password_confirmation: passwordConfirm,
        token,
      });
      return response.data;
    } catch (error) {
      Toast.fire("Tivemos um problema!", "Ocorreu um problema por favor contate o suporte!", "error");
      throw error;
    } finally {
      updateLoading("reset-password", false);
    }
  }

  async function getProjects() {
    try {
      updateLoading("getProjects", true);
      const response = await api.get("/projects");
      return response.data;
    } catch (error) {
      Toast.fire("Tivemos um problema!", "Erro ao buscar projetos, por favor contate o suporte!", "error");
      throw error;
    } finally {
      updateLoading("getProjects", false);
    }
  }

  async function listFiles() {
    try {
      updateLoading("list-files", true);
      const response = await api.get("/files");
      for (const file of response.data) {
        file.src = file.url;
        file.preview = file.thumbnail || file.url;
        file.type = file.type ? file.type : "image";
      }
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      updateLoading("list-files", false);
    }
  }

  async function uploadFile({ file }) {
    try {
      updateLoading("upload-file", true);
      const response = await api.postForm("/files/upload", { file });
      Toast.fire("Tudo certo!", "Arquivo enviado com sucesso!", "success");
      return response.data;
    } catch (error) {
      Toast.fire("Tivemos um problema", "Erro ao enviar arquivo, por favor contate o suporte!", "error");
      throw error;
    } finally {
      updateLoading("upload-file", false);
    }
  }

  async function deleteFile({ id }) {
    try {
      updateLoading("delete-file", true);
      const response = await api.delete(`/files/${id}`);
      Toast.fire("Tudo certo!", "Arquivo detetado com sucesso!", "success");
      return response;
    } catch (error) {
      Toast.fire("Tivemos um problema", "Erro ao deletar arquivo, por favor contate o suporte!", "error");
      throw error;
    } finally {
      updateLoading("delete-file", false);
    }
  }

  async function createFolder(folderName) {
    try {
      const response = await api.post("/folders", {
        name: folderName,
      });
      return response.data;
    } catch (error) {
      console.error("Erro ao criar pasta:", error);
      throw error;
    }
  }

  // Função para mover um projeto para uma pasta
  const moveProjectToFolder = async (projectId, folderName) => {
    try {
      const response = await api.put(`/projects/${projectId}/move`, { folder_id: folderName });
      return response.data;
    } catch (error) {
      console.error("Erro ao mover projeto:", error);
      throw error;
    }
  };

  // Função para listar todas as pastas do usuário
  const getFolders = async () => {
    try {
      const response = await api.get("/folders");
      return response.data;
    } catch (error) {
      console.error("Erro ao listar pastas:", error);
      throw error;
    }
  };

  return {
    loading,
    updateLoading,
    userData,
    login,
    register,
    getProjects,
    sendRecoveryPassword,
    updatePassword,
    listFiles,
    uploadFile,
    deleteFile,
    getHomeData,
    createFolder,
    moveProjectToFolder,
    getFolders,
  };
};
