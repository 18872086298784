import React, { useEffect, useState } from "react";
import { Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

import { useApi } from "../hooks/useApi";
import { Navbar } from "../components/navbar";

import { FullscreenLoading } from "../components/loading-fullscreen";
import { Accordion } from "../components/accordion";
import { AccordionItem } from "../components/accordion-item";
import { TutorialLink } from "../components/tutorial-link";
import { ProjectsList } from "../components/projects-list";

export const Home = () => {
  const { loading, userData, getHomeData } = useApi();
  const [user, setUser] = useState({ name: "Cliente" });
  const [name, setName] = useState();
  const [data, setData] = useState({
    notifications: { content: "", link: "", type: "" },
    advertising: [],
    getStarter: [],
    messages: [],
    welcomeVideo: "",
  });

  useEffect(() => {
    (async () => {
      const res = await userData();
      const homeData = await getHomeData();
      setData((prev) => ({
        ...prev,
        getStarter: homeData.articles,
      }));
      setUser(res);
    })();
  }, []);

  if (loading["user-data"] || loading["home-data"])
    return <FullscreenLoading state={true} />;

  return (
    <div className="flex flex-col items-center min-h-screen bg-dark-150">
      <Navbar user={user} />
      <main className="flex justify-center w-full px-4 transition-all">
        <section className="flex flex-col w-full gap-8 my-4 transition-all xl:mx-8 xl:max-w-screen-xl 2xl:max-w-screen-2xl">
          <div className="header">
            <h1 className="text-lg font-medium font-sora text-light-0">
              Seja bem-vindo,
              <span className="text-primary-600 font-sora">
                {" "}
                {user["name"] || "Cliente"}
              </span>
              !
            </h1>
          </div>

          <div className="flex flex-wrap gap-8">
            <div className="w-full lg:w-2/3"> {/* Aumenta o espaço para os projetos */}
              <div className="flex flex-col gap-8">
                {data.advertising.length > 0 && (
                  <Swiper
                    modules={[Pagination, Autoplay]}
                    className="max-w-fill rounded-tiny block w-full max-sm:min-h-[94px] aspect-[6.25]"
                    spaceBetween={16}
                    slidesPerView={1}
                    centeredSlides
                    autoplay={{ delay: 8000 }}
                    loop
                    pagination={{ clickable: true }}
                  >
                    {data.advertising.map((banner) => (
                      <SwiperSlide key={banner.id} className="min-w-full">
                        <a
                          href={banner.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="relative"
                        >
                          <div className="bg-dark-250 animate-pulse rounded-tiny w-full max-sm:min-h-[94px] aspect-[6.25] absolute -z-10 flex justify-center items-center">
                            <p className="text-base font-medium text-light-800">
                              Carregando banner...
                            </p>
                          </div>
                          <img
                            src={banner.image}
                            className="rounded-tiny w-full max-sm:min-h-[94px] object-cover"
                            alt={banner.alt}
                            loading="lazy"
                          />
                        </a>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                )}

                <ProjectsList />
              </div>
            </div>
            <div className="flex flex-col w-full gap-8 lg:w-1/3"> {/* Reduz o espaço para tutoriais */}
              {data.messages.length > 0 && (
                <div className="flex flex-col w-full gap-4 p-8 rounded-tiny bg-dark-250">
                  <div className="mb-2">
                    <h3 className="text-base font-medium font-sora text-light-0">
                      Suas mensagens
                    </h3>
                    <hr
                      width="80"
                      className="mt-1 border-t-1 border-primary-600"
                    />
                  </div>
                  <Accordion>
                    {data.messages
                      .sort((a, b) => a.priority - b.priority)
                      .map((message) => (
                        <AccordionItem
                          key={message.id}
                          title={message.title}
                          type={message.type}
                          html={message.description}
                        />
                      ))}
                  </Accordion>
                </div>
              )}
              {data.getStarter.length > 0 && (
                <div className="flex flex-col w-full gap-4 p-8 rounded-tiny bg-dark-250">
                  <div className="mb-2">
                    <h3 className="text-base font-medium font-sora text-light-0">
                      Primeiros passos
                    </h3>
                    <hr
                      width="80"
                      className="mt-1 border-t-1 border-primary-600"
                    />
                  </div>
                  <ul className="tutorial">
                    {data.getStarter
                      .sort((a, b) => a.priority - b.priority)
                      .map((tutorial) => (
                        <TutorialLink
                          key={tutorial.id}
                          title={tutorial.title}
                          link={tutorial.link}
                        />
                      ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};
