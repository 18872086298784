import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApi } from "../hooks/useApi";
import Swal from "sweetalert2";
import { Button } from "./button";

export const ProjectsList = () => {
  const { getProjects, createFolder, moveProjectToFolder, getFolders } = useApi(); // Incluindo as funções de API
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [folders, setFolders] = useState([]);
  const [selectedFolderId, setSelectedFolderId] = useState(null); // Agora usamos o folder_id
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Busca os projetos e as pastas do usuário
    const fetchData = async () => {
      try {
        const projectsData = await getProjects();
        setProjects(projectsData);
        setFilteredProjects(projectsData);

        // Gera a lista de pastas a partir da API
        const foldersData = await getFolders();
        setFolders(foldersData);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Filtro de projetos baseado no folder_id selecionado
  useEffect(() => {
    if (selectedFolderId) {
      setFilteredProjects(
        projects.filter((project) => project.folder_id === selectedFolderId)
      );
    } else {
      setFilteredProjects(projects); // Exibe todos os projetos se nenhuma pasta for selecionada
    }
  }, [selectedFolderId, projects]);

  const handleAddProject = () => {
    navigate("/app");
  };

  const handleProjectClick = (id) => {
    localStorage.setItem("braip-creative-last-design-id", id);
    navigate("/app");
  };

  const handleCreateFolder = async () => {
    const { value: folderName } = await Swal.fire({
      title: "Criar nova pasta",
      input: "text",
      inputLabel: "Nome da pasta",
      inputPlaceholder: "Digite o nome da nova pasta",
      showCancelButton: true,
    });

    if (folderName) {
      try {
        const newFolder = await createFolder(folderName);
        // Adiciona a nova pasta na lista local
        setFolders([...folders, newFolder]);
        Swal.fire("Sucesso", `A pasta "${newFolder.name}" foi criada!`, "success");
      } catch (error) {
        Swal.fire("Erro", "Ocorreu um erro ao criar a pasta.", "error");
      }
    }
  };

  const handleFolderSelection = (folderId) => {
    setSelectedFolderId(folderId); // Define o folder_id selecionado
  };

  const handleMoveProject = async (projectId) => {
    const { value: folderId } = await Swal.fire({
      title: "Mover projeto para a pasta",
      input: "select",
      inputOptions: folders.reduce((acc, folder) => {
        acc[folder.id] = folder.name; // Usa o id da pasta como valor e exibe o nome
        return acc;
      }, {}),
      inputPlaceholder: "Selecione a pasta",
      showCancelButton: true,
    });

    if (folderId) {
      try {
        await moveProjectToFolder(projectId, folderId);
        // Atualiza o projeto na lista local
        const updatedProjects = projects.map((p) =>
          p.id === projectId ? { ...p, folder_id: folderId } : p
        );
        setProjects(updatedProjects);
        setFilteredProjects(updatedProjects);
        Swal.fire("Sucesso", "Projeto movido para a pasta!", "success");
      } catch (error) {
        Swal.fire("Erro", "Ocorreu um erro ao mover o projeto.", "error");
      }
    }
  };

  if (loading) {
    return (
      <div className="flex gap-4">
        {/* Skeleton para a listagem de pastas */}
        <div className="flex flex-col w-64 gap-4">
          <div className="h-6 mb-4 rounded-md bg-dark-200 animate-pulse"></div>
          <div className="flex flex-col gap-2">
            {/* Skeleton para cada item de pasta */}
            {Array.from({ length: 3 }).map((_, index) => (
              <div
                key={index}
                className="flex items-center p-2 rounded-md bg-dark-200 animate-pulse"
              >
                <div className="w-6 h-6 mr-2 rounded-md bg-dark-150"></div>
                <div className="w-24 h-4 rounded-md bg-dark-150"></div>
              </div>
            ))}
          </div>
        </div>

        {/* Skeleton para a listagem de projetos */}
        <div className="flex-1">
          <div className="flex items-center gap-4 mb-4">
            <div className="flex-1 h-10 rounded-md bg-dark-200 animate-pulse"></div>
            <div className="h-10 rounded-md w-36 bg-primary-600 animate-pulse"></div>
          </div>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
            {Array.from({ length: 6 }).map((_, index) => (
              <div key={index} className="p-4 rounded-md shadow-md bg-dark-250">
                <div className="h-48 mb-2 rounded-md bg-dark-200 animate-pulse"></div>
                <div className="h-6 mb-2 rounded-md bg-dark-200 animate-pulse"></div>
                <div className="h-4 rounded-md bg-dark-200 animate-pulse"></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex gap-4">
      {/* Listagem de pastas ao estilo Google Drive */}
      <div className="flex flex-col w-64 gap-4">
        <h3 className="text-lg font-bold text-light-0">Pastas</h3>

        <Button 
          text="Criar Nova Pasta"
          type="button"
          className="text-center"
          size="small"
          variant="secundary"
          semibold
          full
          icon="plus"
          onClick={handleCreateFolder}
        />

        <div className="flex flex-col gap-2">
          {/* Opção para "Todas as Pastas" */}
          <div
            className={`flex items-center p-2 rounded-md cursor-pointer ${selectedFolderId === null ? 'bg-gray-600 bg-opacity-30 text-white' : 'bg-dark-150 text-light-0'
              }`}
            onClick={() => handleFolderSelection(null)} // Reseta o filtro para "todas as pastas"
          >
            <svg
              className="w-6 h-6 mr-2"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M2 4a2 2 0 012-2h12a2 2 0 012 2v10a2 2 0 01-2 2H4a2 2 0 01-2-2V4z" />
            </svg>
            Todas as Pastas
          </div>

          {/* Listagem de pastas */}
          {folders.map((folder) => (
            <div
              key={folder.id}
              className={`flex items-center p-2 rounded-md cursor-pointer ${selectedFolderId === folder.id ? 'bg-gray-600 bg-opacity-30 text-white' : 'bg-dark-150 text-light-0'
                }`}
              onClick={() => handleFolderSelection(folder.id)} // Define o folder_id para o filtro
            >
              <svg
                className="w-6 h-6 mr-2"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                {selectedFolderId === folder.id ? (
                  <path d="M2 4a2 2 0 012-2h4a2 2 0 011.732 1H16a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V4z" />
                ) : (
                  <path d="M2 4a2 2 0 012-2h4a2 2 0 011.732 1H16a2 2 0 012 2v10a2 2 0 01-2 2H4a2 2 0 01-2-2V4z" />
                )}
              </svg>
              {folder.name}
            </div>
          ))}
        </div>
      </div>

      {/* Listagem de projetos filtrados */}
      <div className="flex-1">
        <div className="flex items-center gap-4 mb-4">
          <input
            type="text"
            placeholder="Pesquisar em meus projetos..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="flex-1 p-2 border border-gray-600 bg-dark-150 text-light-0"
          />

          <Button 
          text="Criar Novo Projeto"
          type="button"
          className="text-center"
          size="small"
          variant="primary"
          semibold
          icon="new-page"
          onClick={handleAddProject}
        />
        </div>

        {filteredProjects.length > 0 ? (
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
            {filteredProjects.map((project) => (
              <div
                key={project.id}
                className="p-4 rounded-md shadow-md cursor-pointer bg-dark-250"
                onClick={() => handleProjectClick(project.id)}
              >
                <img
                  src={project.thumbnail}
                  alt={project.title}
                  className="object-cover w-full h-48 mb-2 rounded-md"
                />
                <h3 className="text-lg font-semibold text-light-0">
                  {project.title}
                </h3>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleMoveProject(project.id);
                  }}
                  className="text-sm text-blue-500"
                >
                  Mover para pasta
                </button>
              </div>
            ))}
          </div>
        ) : (
          <div className="p-4 text-center rounded-md bg-dark-250 text-light-500">
            Nenhum projeto encontrado.
          </div>
        )}
      </div>
    </div>
  );
};
